/**
* Template Name: eStartup
* Template URL: https://bootstrapmade.com/estartup-bootstrap-landing-page-template/
* Updated: Aug 07 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */

@import url('https://fonts.googleapis.com/css2?family=Playfair:ital,opsz,wght@0,5..1200,300..900;1,5..1200,300..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
:root {
  --default-font: "Roboto",  system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --heading-font: "Raleway",  sans-serif;
  --nav-font: "Poppins",  sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root { 
  --background-color: #ffffff; /* Background color for the entire website, including individual sections */
  --default-color: #212529; /* Default color used for the majority of the text content across the entire website */
  --heading-color: #101f0c; /* Color for headings, subheadings and title throughout the website */
  --accent-color: #9dce3f; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --surface-color: #ffffff; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
  --contrast-color: #ffffff; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
  --dark-green-color:#154c60;
  --secondary-text-color:#333333;
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
  --nav-color: #212529;  /* The default color of the main navmenu links */
  --nav-hover-color: #9dce3f; /* Applied to main navmenu links when they are hovered over or active */
  --nav-mobile-background-color: #ffffff; /* Used as the background color for mobile navigation menu */
  --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
  --nav-dropdown-color: #212529; /* Used for navigation links of the dropdown items in the navigation menu. */
  --nav-dropdown-hover-color: #9dce3f; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */

.LP .light-background {
  /* --background-color: #f1f6f1; */
  --background-color: rgba(157, 206, 63,0.1);
  --surface-color: #ffffff;
}

.LP .dark-background {
  --background-color: #060606;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #252525;
  --contrast-color: #ffffff;
}


/* Smooth scroll */
:root {
  scroll-behavior: smooth;
}

/*--------------------------------------------------------------
# General Styling & Shared Classes
--------------------------------------------------------------*/
 body {
  color: var(--default-color);
  background-color: var(--background-color);
  font-family: var(--default-font);
}

.LP a {
  color: var(--accent-color);
  text-decoration: none;
  transition: 0.3s;
}

.LP a:hover {
  color: color-mix(in srgb, var(--accent-color), transparent 25%);
  text-decoration: none;
}

.LP h1,
.LP h2,
.LP h3,
.LP h4,
.LP h5,
.LP h6 {
  color: var(--heading-color);
  font-family: var(--heading-font);
}

/* PHP Email Form Messages
------------------------------*/
.LP .php-email-form .error-message {
  display: none;
  background: #df1529;
  color: #ffffff;
  text-align: left;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.LP .php-email-form .sent-message {
  display: none;
  color: #ffffff;
  background: #059652;
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
  font-weight: 600;
}

.LP .php-email-form .loading {
  display: none;
  background: var(--surface-color);
  text-align: center;
  padding: 15px;
  margin-bottom: 24px;
}

.LP .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid var(--accent-color);
  border-top-color: var(--surface-color);
  animation: php-email-form-loading 1s linear infinite;
}

@keyframes php-email-form-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Global Header
--------------------------------------------------------------*/
.LP .header {
  color: var(--default-color);
  background-color: var(--background-color);
  /* padding: 15px 0; */
  transition: all 0.5s;
  z-index: 997;
  height: 79px;
}

.LP .header .logo {
  line-height: 1;
}

.LP .header .logo img {
  max-height: 75px;
  /* max-height: 32px; */
  margin-right: 8px;
}

.LP .header .logo h1 {
  font-size: 30px;
  margin: 0;
  font-weight: 700;
  color: var(--heading-color);
}

.LP .header .logo h1 span {
  color: var(--accent-color);
}

.LP .scrolled .header {
  box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Navmenu - Desktop */
@media (min-width: 1200px) {
  .LP .navmenu {
    padding: 0;
  }

  .LP .navmenu ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  .LP .navmenu li {
    position: relative;
  }

 .LP .navmenu a,
  .LP .navmenu a:focus {
    color: var(--nav-color);
    padding: 18px 15px;
    font-size: 16px;
    font-family: var(--nav-font);
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .LP .navmenu a i,
  .LP .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    transition: 0.3s;
  }

  /* .LP .navmenu li:last-child a {
    padding-right: 0;
  } */

  .LP .navmenu li:hover>a,
  .LP .navmenu .active,
  .LP .navmenu .active:focus {
    color: var(--nav-hover-color);
  }

  .LP .navmenu .dropdown ul {
    margin: 0;
    padding: 10px 0;
    background: var(--nav-dropdown-background-color);
    display: block;
    position: absolute;
    visibility: hidden;
    left: 14px;
    top: 130%;
    opacity: 0;
    transition: 0.3s;
    border-radius: 4px;
    z-index: 99;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .LP .navmenu .dropdown ul li {
    min-width: 200px;
  }

  .LP .navmenu .dropdown ul a {
    padding: 10px 20px;
    font-size: 15px;
    text-transform: none;
    color: var(--nav-dropdown-color);
  }

  .LP .navmenu .dropdown ul a i {
    font-size: 12px;
  }

  .LP .navmenu .dropdown ul a:hover,
  .LP .navmenu .dropdown ul .active:hover,
  .LP .navmenu .dropdown ul li:hover>a {
    color: var(--nav-dropdown-hover-color);
  }

  .LP .navmenu .dropdown:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }

  .LP .navmenu .dropdown .dropdown ul {
    top: 0;
    left: -90%;
    visibility: hidden;
  }

  .LP .navmenu .dropdown .dropdown:hover>ul {
    opacity: 1;
    top: 0;
    left: -100%;
    visibility: visible;
  }
}

/* Navmenu - Mobile */
@media (max-width: 1199px) {
  .LP .mobile-nav-toggle {
    color: var(--nav-color);
    font-size: 28px;
    line-height: 0;
    margin-right: 10px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .LP .navmenu {
    padding: 0;
    z-index: 9997;
  }

  .LP .navmenu ul {
    display: none;
    list-style: none;
    position: absolute;
    inset: 60px 20px 20px 20px;
    padding: 10px 0;
    margin: 0;
    border-radius: 6px;
    background-color: var(--nav-mobile-background-color);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .LP .navmenu a,
  .LP .navmenu a:focus {
    color: var(--nav-dropdown-color);
    padding: 10px 20px;
    font-family: var(--nav-font);
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    transition: 0.3s;
  }

  .LP .navmenu a i,
  .LP .navmenu a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 0.3s;
    background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  }

  .LP .navmenu a i:hover,
  .LP .navmenu a:focus i:hover {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }

  .LP .navmenu a:hover,
  .LP .navmenu .active,
  .LP .navmenu .active:focus {
    color: var(--nav-dropdown-hover-color);
  }

  .LP .navmenu .active i,
  .LP .navmenu .active:focus i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    transform: rotate(180deg);
  }

  .LP .navmenu .dropdown ul {
    position: static;
    display: none;
    z-index: 99;
    padding: 10px 0;
    margin: 10px 20px;
    background-color: var(--nav-dropdown-background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    box-shadow: none;
    transition: all 0.5s ease-in-out;
  }

  .LP .navmenu .dropdown ul ul {
    background-color: rgba(33, 37, 41, 0.1);
  }

  .LP .navmenu .dropdown>.dropdown-active {
    display: block;
    background-color: rgba(33, 37, 41, 0.03);
  }

  .LP.mobile-nav-active {
    overflow: hidden;
  }

  .LP.mobile-nav-active .mobile-nav-toggle {
    color: #fff;
    position: absolute;
    font-size: 32px;
    top: 15px;
    right: 15px;
    margin-right: 0;
    z-index: 9999;
  }

  .LP.mobile-nav-active .navmenu {
    position: fixed;
    overflow: hidden;
    inset: 0;
    background: rgba(33, 37, 41, 0.8);
    transition: 0.3s;
  }

  .LP.mobile-nav-active .navmenu>ul {
    display: block;
  }
}

/*--------------------------------------------------------------
# Global Footer
--------------------------------------------------------------*/
/* .footer {
  color: var(--default-color);
  background-color: var(--background-color);
  font-size: 14px;
  padding: 40px 0;
  position: relative;
}

.footer .copyright p {
  margin-bottom: 0;
}

.footer .social-links {
  margin-top: 20px;
}

.footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 16px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  margin: 0 5px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: var(--accent-color);
  border-color: var(--accent-color);
}

.footer .credits {
  margin-top: 10px;
  font-size: 13px;
  text-align: center;
} */

/*--------------------------------------------------------------
# Global Footer
--------------------------------------------------------------*/
.LP-footer {
  color: var(--default-color);
  background-color: var(--dark-green-color);
  font-size: 14px;
  position: relative;
}

.LP-footer .footer-top {
  padding-top: 50px;
}

.LP-footer .footer-about .logo {
  line-height: 1;
  margin-bottom: 25px;
}

.LP-footer .footer-about .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.LP-footer .footer-about .logo span {
  color: var(--contrast-color);
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  font-family: var(--heading-font);
}

.LP-footer .footer-about p {
  font-size: 14px;
  font-family: var(--heading-font);
  color: var(--contrast-color);
  font-weight: 400;
  line-height: 1.5;
}

@media(max-width:990px){
  .LP-footer .social-links{
    justify-content: start;
  }
}
@media(max-width:760px){
  .LP-footer .social-links{
    justify-content: center;
  }
}

.LP-footer .social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid color-mix(in srgb, var(--contrast-color), transparent 50%);
  font-size: 16px;
  color: color-mix(in srgb, var(--contrast-color), transparent 50%);
  margin-right: 10px;
  transition: 0.3s;
}

.LP-footer .social-links a:hover {
  color: var(--contrast-color);
  border-color: var(--contrast-color);
}

.LP-footer h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
  color: var(--contrast-color);
}

.LP-footer .footer-links {
  margin-bottom: 30px;
}

.LP-footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.LP-footer .footer-links ul i {
  padding-right: 2px;
  font-size: 12px;
  line-height: 0;
}

.LP-footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;

}

.LP-footer .footer-links ul li:first-child {
  padding-top: 0;
}

.LP-footer .footer-links ul a {
  color: var(--contrast-color);
  /* color: color-mix(in srgb, var(--default-color), transparent 30%); */
  display: inline-block;
  line-height: 1;
}

.LP-footer .footer-links ul a:hover {
  color: var(--accent-color);
}

.LP-footer .footer-contact p {
  margin-bottom: 5px;
  color:var(--contrast-color);
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
}

.LP-footer .copyright {

  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid color-mix(in srgb, var(--accent-color), transparent 90%);
}


.LP-footer .copyright p {
  color: var(--contrast-color);
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;

}
.LP-footer .copyright .policyLink{
  text-align: right;
}
@media(max-width:760px){
  .LP-footer .copyright .policyLink{
    text-align: left;
  }
}

.LP-footer .credits {
  margin-top: 4px;
  font-size: 13px;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
.LP #preloader {
  position: fixed;
  inset: 0;
  z-index: 9999;
  overflow: hidden;
  background-color: var(--background-color);
  transition: all 0.6s ease-out;
}

.LP #preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid var(--accent-color);
  border-top-color: color-mix(in srgb, var(--accent-color), transparent 90%);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: animate-preloader 1s linear infinite;
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Scroll Top Button
--------------------------------------------------------------*/
.LP .scroll-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: -15px;
  z-index: 99999;
  background-color: var(--accent-color);
  width: 44px;
  height: 44px;
  border-radius: 50px;
  transition: all 0.4s;
}

.LP .scroll-top i {
  font-size: 24px;
  color: var(--contrast-color);
  line-height: 0;
}

.LP .scroll-top:hover {
  background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
  color: var(--contrast-color);
}

.LP .scroll-top.active {
  visibility: visible;
  opacity: 1;
  bottom: 15px;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Global Page Titles & Breadcrumbs
--------------------------------------------------------------*/
.LP .page-title {
  color: var(--default-color);
  background-color: var(--background-color);
  position: relative;
}

.LP .page-title .heading {
  padding: 80px 0;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.LP .page-title .heading h1 {
  font-size: 38px;
  font-weight: 700;
}

.LP .page-title nav {
  background-color: color-mix(in srgb, var(--default-color), transparent 95%);
  padding: 20px 0;
}

.LP .page-title nav ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.LP .page-title nav ol li+li {
  padding-left: 10px;
}

.LP .page-title nav ol li+li::before {
  content: "/";
  display: inline-block;
  padding-right: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

/*--------------------------------------------------------------
# Global Sections
--------------------------------------------------------------*/
.LP section,
.section {
  color: var(--default-color);
  background-color: var(--background-color);
  padding: 60px 0;
  scroll-margin-top: 90px;
  overflow: clip;
}

@media (max-width: 1199px) {

  .LP section,
  .section {
    scroll-margin-top: 66px;
  }
}

/*--------------------------------------------------------------
# Global Section Titles
--------------------------------------------------------------*/
.LP .section-title {
  text-align: center;
  padding-bottom: 60px;
  position: relative;
}

.LP .section-title h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: color-mix(in srgb, var(--accent-color), transparent 90%);
  color: var(--accent-color);
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
  font-family: var(--default-font);
}

.LP .section-title div {
  color: var(--dark-green-color);
  margin: 10px 0 0 0;
  font-size: 32px;
  font-weight: 700;
  font-family: var(--heading-font);
}

.LP .section-title div .description-title {
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.LP .hero {
  width: 100%;
  position: relative;
  padding: 120px 0 0 0;
}

.LP .hero .container {
  position: relative;
  z-index: 3;
}

.LP .hero h2 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var( --dark-green-color);
 
}

.LP .hero h2 .accent {
  color: var(--accent-color);
}

.LP .hero p {
  color: color-mix(in srgb, var(--secondary-text-color), transparent 20%);
  font-weight: 400;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.5;
}

.LP .hero .btn-get-started {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 36px;
  border-radius: 50px;
  transition: 0.5s;
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

.LP .hero .btn-get-started:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 15%);
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

.LP .hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  font-weight: 600;
  color: var(--accent-color);
}

.LP .hero .btn-watch-video i {
  color: var(--accent-color);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.LP .hero .btn-watch-video:hover {
  color: var(--accent-color);
}

.LP .hero .btn-watch-video:hover i {
  color: color-mix(in srgb, var(--accent-color), transparent 15%);
}

@media (max-width: 640px) {
  .LP .hero h2 {
    font-size: 36px;
  }

  .LP .hero .btn-get-started,
  .LP .hero .btn-watch-video {
    font-size: 14px;
  }
}

.LP .hero .icon-boxes {
  padding-bottom: 60px;
  z-index: 4;
}

.LP .hero .icon-box {
  background: var(--surface-color);
  padding: 60px 30px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  height: 100%;
  width: 100%;
  text-align: center;
}

.LP .hero .icon-box .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.LP .hero .icon-box .title a {
  color: color-mix(in srgb, var(--secondary-text-color), transparent 20%);
  transition: 0.3s;
}

.LP .hero .icon-box .icon {
  margin-bottom: 20px;
  padding-top: 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-size: 48px;
  line-height: 1;
  color: var(--dark-green-color);
}

.LP .hero .icon-box:hover {
  background-color: var(--dark-green-color);
}

.LP .hero .icon-box:hover .title a,
.LP .hero .icon-box:hover .icon {
  color: var(--contrast-color);
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.LP .about .content .who-we-are {
  text-transform: uppercase;
  margin-bottom: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}

.LP .about .content h3 {
  font-size: 2rem;
  font-weight: 700;
  color: var(--dark-green-color);
}

.LP .about .content ul {
  list-style: none;
  padding: 0;
  color: #333333;
}

.LP .about .content ul li {
  padding-bottom: 10px;
}

.LP .about .content ul i {
  font-size: 1.25rem;
  margin-right: 4px;
  color: var(--accent-color);
}

.LP .about .content p:last-child {
  margin-bottom: 0;
}

.LP .about .content .read-more {
  background: var(--accent-color);
  color: var(--contrast-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  padding: 12px 24px;
  border-radius: 5px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.LP .about .content .read-more i {
  font-size: 18px;
  margin-left: 5px;
  line-height: 0;
  transition: 0.3s;
}

.LP .about .content .read-more:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
  padding-right: 19px;
}

.LP .about .content .read-more:hover i {
  margin-left: 10px;
}

.LP .about .about-images img {
  border-radius: 10px;
}

/*--------------------------------------------------------------
# keyFeatures Section
--------------------------------------------------------------*/
.LP .keyFeatures .keyFeatures-item {
  background-color: var(--surface-color);
  box-shadow: 0px 5px 90px 0px rgba(0, 0, 0, 0.1);
  padding: 60px 30px;
  transition: all ease-in-out 0.3s;
  border-radius: 18px;
  border-bottom: 5px solid var(--surface-color);
  height: 100%;
}

.LP .keyFeatures .keyFeatures-item .icon {
  color: var(--contrast-color);
  background: var(--accent-color);
  margin: 0;
  width: 64px;
  height: 64px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.LP .keyFeatures .keyFeatures-item h3 {
  font-weight: 700;
  margin: 10px 0 15px 0;
  font-size: 22px;
  transition: ease-in-out 0.3s;
  color: var(--dark-green-color);
}


.LP .keyFeatures .keyFeatures-item p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  color: #333333;
  font-weight: 400;
}

@media (min-width: 1365px) {
  .LP .keyFeatures .keyFeatures-item:hover {
    transform: translateY(-10px);
    border-color: var(--accent-color);
  }

  .LP .keyFeatures .keyFeatures-item:hover h3 {
    color: var(--accent-color);
  }
}

/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.LP .features .feature-box {
  background-color: var(--surface-color);
  padding: 24px 20px;
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  height: 100%;
}

.LP .features .feature-box h3 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  color: var(--secondary-text-color);
}

.LP .features .feature-box i {
  background: color-mix(in srgb, var(--accent-color), transparent 92%);
  color: var(--accent-color);
  line-height: 0;
  padding: 4px;
  margin-right: 10px;
  font-size: 24px;
  border-radius: 3px;
  transition: 0.3s;
}

.LP .features .feature-box:hover i {
  background: var(--accent-color);
  color: var(--contrast-color);
}

/*--------------------------------------------------------------
# Pricing Section
--------------------------------------------------------------*/
.LP .pricing .pricing-tem {
  background-color: var(--surface-color);
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0 30px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;
  text-align: center;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
  height: fit-content;
}

@media (min-width: 1200px) {
  .LP .pricing .pricing-tem:hover {
    transform: scale(1.1);
  }
}

.LP .pricing h3 {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}

.LP .pricing .price {
  font-size: 36px;
  color: var(--heading-color);
  font-weight: 600;
  font-family: var(--heading-font);
}

.LP .pricing .price sup {
  font-size: 20px;
  top: -15px;
  left: -3px;
}

.LP .pricing .price span {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 16px;
  font-weight: 300;
}

.LP .pricing .icon {
  padding: 20px 0;
}

.pricing .icon i {
  font-size: 48px;
}

.LP .pricing ul {
  padding: 0;
  list-style: none;
  color: var(--secondary-text-color);
  text-align: center;
  line-height: 26px;
  font-size: 16px;
  margin-bottom: 25px;
}

.LP .pricing ul li {
  padding-bottom: 10px;
}

.LP .pricing ul .na {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
  text-decoration: line-through;
}

.LP .pricing .btn-buy {
  display: inline-block;
  padding: 8px 40px 10px 40px;
  border-radius: 50px;
  color: var(--accent-color);
  transition: none;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--heading-font);
  font-weight: 600;
  transition: 0.3s;
  border: 1px solid var(--accent-color);
}

.LP .pricing .btn-buy:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.LP .pricing .featured {
  width: 200px;
  position: absolute;
  top: 18px;
  right: -68px;
  transform: rotate(45deg);
  z-index: 1;
  font-size: 14px;
  padding: 1px 0 3px 0;
  background: var(--accent-color);
  color: var(--contrast-color);
}

/*--------------------------------------------------------------
# Faq Section
--------------------------------------------------------------*/
.LP .faq .content h3 {
  font-weight: 400;
  font-size: 34px;
}

.LP .faq .content p {
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 15%);
  line-height: 1.5;
  font-weight: 400;
}

.LP .faq .faq-container .faq-item {
  background-color: var(--surface-color);
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.LP .faq .faq-container .faq-item:last-child {
  margin-bottom: 0;
}

.LP .faq .faq-container .faq-item h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0 30px 0 0;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}

.LP .faq .faq-container .faq-item h3 .num {
  color: var(--dark-green-color);
  padding-right: 5px;
}

.LP .faq .faq-container .faq-item h3:hover {
  color: var(--dark-green-color);
}

.LP .faq .faq-container .faq-item .faq-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.LP .faq .faq-container .faq-item .faq-content p {
  margin-bottom: 0;
  overflow: hidden;
  color: var(--secondary-text-color);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}

.LP .faq .faq-container .faq-item .faq-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  line-height: 0;
  transition: 0.3s;
  cursor: pointer;
}

.LP .faq .faq-container .faq-item .faq-toggle:hover {
  color: var(--accent-color);
}

.LP .faq .faq-container .faq-active h3 {
  color: var(--dark-green-color);
}

.LP .faq .faq-container .faq-active .faq-content {
  grid-template-rows: 1fr;
  visibility: visible;
  opacity: 1;
  padding-top: 10px;
}

.LP .faq .faq-container .faq-active .faq-toggle {
  transform: rotate(90deg);
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Contact Section
--------------------------------------------------------------*/
.LP .contact .info-item+.info-item {
  margin-top: 40px;
}

.LP .contact .info-item i {
  color: var(--dark-green-color);
  background: color-mix(in srgb, var(--dark-green-color), transparent 92%);
  font-size: 20px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.LP .contact .info-item h3 {
  padding: 0;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
  color: var(--dark-green-color);
}

.LP .contact .info-item p {
  padding: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: var(--secondary-text-color);
  font-weight: 400;
  line-height: 1.5;
}

.LP .contact .info-item:hover i {
  background: var(--accent-color);
  color: var(--contrast-color);
}

.LP .contact .php-email-form {
  height: 100%;
}

.LP .contact .php-email-form input[type=text],
.LP .contact .php-email-form input[type=tel],
.LP .contact .php-email-form input[type=email],
.LP .contact .php-email-form textarea {
  font-size: 14px;
  padding: 10px 15px;
  box-shadow: none;
  border-radius: 0;
  color: var(--default-color);
  /* background-color: color-mix(in srgb, var(--background-color), transparent 50%); */
  border-color: color-mix(in srgb, var(--dark-green-color), transparent 60%);
}

.LP .contact .php-email-form input[type=text]:focus,
.LP .contact .php-email-form input[type=tel]:focus,
.LP .contact .php-email-form input[type=email]:focus,
.LP .contact .php-email-form textarea:focus {
  border-color: var(--dark-green-color);
}

.LP .contact .php-email-form input[type=text]::placeholder,
.LP .contact .php-email-form input[type=tel]::placeholder,
.LP .contact .php-email-form input[type=email]::placeholder,
.LP .contact .php-email-form textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.LP .contact .php-email-form button[type=submit] {
  color: var(--contrast-color);
  background: var(--accent-color);
  border: 0;
  padding: 10px 30px;
  transition: 0.4s;
  border-radius: 50px;
}

.LP .contact .php-email-form button[type=submit]:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 25%);
}

/*--------------------------------------------------------------
# Service Details Section
--------------------------------------------------------------*/
.LP .service-details .service-box {
  background-color: var(--surface-color);
  padding: 20px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
}

.LP .service-details .service-box+.service-box {
  margin-top: 30px;
}

.LP .service-details .service-box h4 {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 2px solid color-mix(in srgb, var(--default-color), transparent 92%);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.LP .service-details .services-list {
  background-color: var(--surface-color);
}

.LP .service-details .services-list a {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  background-color: color-mix(in srgb, var(--default-color), transparent 96%);
  display: flex;
  align-items: center;
  padding: 12px 15px;
  margin-top: 15px;
  transition: 0.3s;
}

.LP .service-details .services-list a:first-child {
  margin-top: 0;
}

.LP .service-details .services-list a i {
  font-size: 16px;
  margin-right: 8px;
  color: var(--accent-color);
}

.LP .service-details .services-list a.active {
  color: var(--contrast-color);
  background-color: var(--accent-color);
}

.LP .service-details .services-list a.active i {
  color: var(--contrast-color);
}

.LP .service-details .services-list a:hover {
  background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
  color: var(--accent-color);
}

.LP .service-details .download-catalog a {
  color: var(--default-color);
  display: flex;
  align-items: center;
  padding: 10px 0;
  transition: 0.3s;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.LP .service-details .download-catalog a:first-child {
  border-top: 0;
  padding-top: 0;
}

.LP .service-details .download-catalog a:last-child {
  padding-bottom: 0;
}

.LP .service-details .download-catalog a i {
  font-size: 24px;
  margin-right: 8px;
  color: var(--accent-color);
}

.LP .service-details .download-catalog a:hover {
  color: var(--accent-color);
}

.LP .service-details .help-box {
  background-color: var(--accent-color);
  color: var(--contrast-color);
  margin-top: 30px;
  padding: 30px 15px;
}

.LP .service-details .help-box .help-icon {
  font-size: 48px;
}

.LP .service-details .help-box h4,
.LP .service-details .help-box a {
  color: var(--contrast-color);
}

.LP .service-details .services-img {
  margin-bottom: 20px;
}

.LP .service-details h3 {
  font-size: 26px;
  font-weight: 700;
}

.LP .service-details p {
  font-size: 15px;
}

.LP .service-details ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.LP .service-details ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
}

.LP .service-details ul i {
  font-size: 20px;
  margin-right: 8px;
  color: var(--accent-color);
}

/*--------------------------------------------------------------
# Starter Section Section
--------------------------------------------------------------*/
.starter-section {
  /* Add your styles here */
}



/*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.LP .call-to-action {
  background-color: transparent;
}

.LP .call-to-action .container {
  padding-top: 80px;
  padding-bottom: 80px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  clip-path: inset(0 round 15px);
}

.LP .call-to-action .container img {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  border-radius: 15px;
  overflow: hidden;
}

.LP .call-to-action .container:before {
  content: "";
  background: 
  color-mix(in srgb, var(--background-color), transparent 20%);
  /* background: rgba(0, 0, 0, 0.7); */
  position: absolute;
  inset: 0;
  z-index: 2;
}

.LP .call-to-action .container .content {
  position: relative;
  z-index: 3;
}

.LP .call-to-action h3 {
  color: var(--default-color);
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}

.LP .call-to-action p {
  color: var(--default-color);
  margin-bottom: 20px;
  line-height: 1.5;
  font-weight: 400;
  font-size: 16px;
}

.LP .call-to-action .play-btn {
  width: 94px;
  height: 94px;
  margin-bottom: 20px;
  background: radial-gradient(var(--accent-color) 50%, color-mix(in srgb, var(--accent-color), transparent 60%) 52%);
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.LP .call-to-action .play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid color-mix(in srgb, var(--accent-color), transparent 20%);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.LP .call-to-action .play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid var(--default-color);
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.LP .call-to-action .play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid var(--default-color);
  z-index: 200;
  animation: none;
  border-radius: 0;
}

.LP .call-to-action .play-btn:hover:after {
  border-left: 15px solid var(--accent-color);
  transform: scale(20);
}

.LP .call-to-action .cta-btn {
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid var(--contrast-color);
  color: var(--contrast-color);
}

.LP .call-to-action .cta-btn:hover {
  background: var(--accent-color);
  border: 2px solid var(--accent-color);
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

/*--------------------------------------------------------------
# key benefits Section
--------------------------------------------------------------*/
.LP .keyBenefits h3 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 30px;
}

.LP .keyBenefits .icon-list i {
  margin-right: 10px;
  font-size: 24px;
  line-height: 1;
}

.LP .keyBenefits .icon-list span {
  font-size: 16px;
  color: color-mix(in srgb, var(--secondary-text-color), transparent 15%);
}

.LP .keyBenefits .phone-wrap {
  position: absolute;
  right: 0;
}

@media (max-width: 768px) {
  .LP .keyBenefits .phone-wrap {
    position: relative;
  }
}

.LP .keyBenefits .phone-wrap img {
  width: 340px;
}

@media (max-width: 992px) {
  .LP .keyBenefits .phone-wrap img {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  .LP .keyBenefits .phone-wrap img {
    width: 100%;
  }
}

.LP .keyBenefits .details {
  margin-top: 80px;
  padding: 120px 0;
  background-color: rgba(240, 240, 240,1);
  /* background-color: color-mix(in srgb, var(--default-color), transparent 97%); */
}
@media (max-width:920px) {
  .LP .keyBenefits .phone-wrap img {
    display: none;
  }
  .LP .keyBenefits .details {
    display: none;
  }
}
@media (max-width:760px) {
  .LP .keyBenefits .phone-wrap img {
    display: inline-block;
  }
  .LP .keyBenefits .details {
    display: none;
  }
}


/*--------------------------------------------------------------
# Call To Action Section
--------------------------------------------------------------*/
.LP .call-to-action2 {
  padding: 80px 0;
  position: relative;
  clip-path: inset(0);
}

.LP .call-to-action2 img {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.LP .call-to-action2:before {
  content: "";
  background: color-mix(in srgb, var(--background-color), transparent 20%);
  position: absolute;
  inset: 0;
  z-index: 2;
}

.LP .call-to-action2 .container {
  position: relative;
  z-index: 3;
}

.LP .call-to-action2 h3 {
  font-size: 28px;
  font-weight: 700;
  color: var(--default-color);
}

.LP .call-to-action2 p {
  color: var(--default-color);
  line-height: 1.5;
  font-size: 16px;
  font-weight: 400;
}

.LP .call-to-action2 .cta-btn {
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid var(--contrast-color);
  color: var(--contrast-color);
}

.LP .call-to-action2 .cta-btn:hover {
  background: var(--accent-color);
  border: 2px solid var(--accent-color);
}


/*--------------------------------------------------------------
# Features Section
--------------------------------------------------------------*/
.LP .caseStudies .caseStudies-item {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.LP .caseStudies .caseStudies-item+.caseStudies-item {
  margin-top: 100px;
}

@media (max-width: 640px) {
  .LP .caseStudies .caseStudies-item+.caseStudies-item {
    margin-top: 40px;
  }
}

.LP .caseStudies .caseStudies-item h3 {
  font-weight: 700;
  font-size: 26px;
}

.LP .caseStudies .caseStudies-item ul {
  list-style: none;
  padding: 0;
  color: var(--secondary-text-color);
}

.LP .caseStudies .caseStudies-item ul li {
  padding-bottom: 10px;
  display: flex;
  /* align-items: center; */
}

.LP .caseStudies .caseStudies-item ul li:last-child {
  padding-bottom: 0;
}

.LP .caseStudies .caseStudies-item ul i {
  font-size: 20px;
  padding-right: 4px;
  color: var(--accent-color);
}

.LP .caseStudies .caseStudies-item p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# SHGWorks Section
--------------------------------------------------------------*/
.LP .SHGWorks .section-title {
  margin-bottom: 60px;
}

.LP .SHGWorks .content {
  background: rgb(157,206,63);
  background: linear-gradient(74deg, rgba(157,206,63,1) 9%, rgba(21,76,96,1) 59%);

  /* background: var(--dark-green-color); */
  color: var(--contrast-color);
  padding: 7rem 0;
}

.LP .SHGWorks .content .img-overlap {
  margin-top: -150px;
}

.LP .SHGWorks p {
  color: var(--contrast-color);
  line-height: 1.5;
  font-weight: 400;
  font-size: 16px;
}

.LP .SHGWorks .content-title {
  color: var(--contrast-color);
  font-weight: 300;
  text-align: left;
}

.LP .SHGWorks .content-title strong {
  font-weight: 700;
}

.LP .SHGWorks .content-subtitle {
  font-weight: 300;
  color: var(--contrast-color);
  text-transform: uppercase;
  font-size: 1.3rem;
}



/*--------------------------------------------------------------
# Testimonials Section
--------------------------------------------------------------*/
.LP .testimonials .testimonial-item {
  background-color: var(--surface-color);
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  padding: 30px;
  margin: 30px 15px;
  position: relative;
  height: 575px;
  /* height: 100%; */
}

.LP .testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  margin-right: 15px;
}

.LP .testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
}

.LP .testimonials .testimonial-item h4 {
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin: 0;
}

.LP .testimonials .testimonial-item .stars {
  margin: 10px 0;
}

.LP .testimonials .testimonial-item .stars i {
  color: #ffc107;
  margin: 0 1px;
}

.LP .testimonials .testimonial-item .quote-icon-left,
.LP .testimonials .testimonial-item .quote-icon-right {
  color: color-mix(in srgb, var(--accent-color), transparent 50%);
  font-size: 26px;
  line-height: 0;
}

.LP .testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.LP .testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
  transform: scale(-1, -1);
}

.LP .testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px auto 15px auto;
  color: var(--secondary-text-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.LP .testimonials .swiper-wrapper {
  height: auto;
}

.LP .testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.LP .testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: color-mix(in srgb, var(--default-color), transparent 85%);
  opacity: 1;
}

.LP .testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--accent-color);
}

@media (max-width: 990px) {
  .LP .testimonials .testimonial-item {

    height: 610px;
  }
}
@media (max-width: 767px) {
  .LP .testimonials .testimonial-wrap {
    padding-left: 0;
  }

  .LP .testimonials .testimonial-item {
    padding: 30px;
    margin: 15px;
    height: 590px;
  }

  .LP .testimonials .testimonial-item .testimonial-img {
    position: static;
    left: auto;
  }
}


.LP .LP-footer .btn-get-started {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 36px;
  border-radius: 50px;
  transition: 0.5s;
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

.LP .LP-footer .btn-get-started:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 15%);
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

/*--------------------------------------------------------------
# Recent Posts Section
--------------------------------------------------------------*/
.LP .recent-posts .post-item {
  background: var(--surface-color);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  height: fit-content !important;
}

.LP .recent-posts .post-item .post-img img {
  transition: 0.5s;
}

.LP .recent-posts .post-item .post-date {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: var(--accent-color);
  color: var(--contrast-color);
  text-transform: uppercase;
  font-size: 13px;
  padding: 6px 12px;
  font-weight: 500;
}

.LP .recent-posts .post-item .post-content {
  padding: 30px;
}

.LP .recent-posts .post-item .post-title {
  color: var(--dark-green-color);
  font-size: 20px;
  font-weight: 700;
  transition: 0.3s;
  margin-bottom: 15px;
}
.LP .recent-posts .post-item .extra-content p{
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;

}

.LP .recent-posts .post-item .meta i {
  font-size: 16px;
  color: var(--accent-color);
}

.LP .recent-posts .post-item .meta span {
  font-size: 15px;
  color: var(--secondary-text-color)
}

.LP .recent-posts .post-item hr {
  color: color-mix(in srgb, var(--default-color), transparent 80%);
  margin: 20px 0;
}

.LP .recent-posts .post-item .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.LP .recent-posts .post-item .readmore i {
  line-height: 0;
  margin-left: 6px;
  font-size: 16px;
}

.LP .recent-posts .post-item:hover .post-title,
.LP .recent-posts .post-item:hover .readmore {
  color: var(--accent-color);
}

.LP .recent-posts .post-item:hover .post-img img {
  transform: scale(1.1);
}


/* blogs  */
.LP .extra-content {
  display: none; /* Hidden by default */
}
.LP .viewMoreLess-btn{
color: #154c60;
text-decoration: underline;
margin-bottom: 10px;
}
.LP .viewMoreLess-btn:hover{
  color: #042b19;
  text-decoration: underline;
  margin-bottom: 10px;
  }
 .LP .mt-5E {
    margin-top: 5rem !important;

  }
  .LP .secondaryText-color{
    color: var(--secondary-text-color);
  }
  .LP .inactiveLink {
    pointer-events: none;
    cursor: default;
 }
.LP .fontText-small{
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.LP .navmenu .btn-get-started {
  color: var(--contrast-color);
  background: var(--accent-color);
  font-family: var(--heading-font);
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 36px;
  border-radius: 50px;
  transition: 0.5s;
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.LP .navmenu .btn-get-started:hover ,.LP .navmenu .btn-get-started:focus,.LP .navmenu .btn-get-started:focus-visible {
  background: color-mix(in srgb, var(--accent-color), transparent 15%);
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 12px 36px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 500;
  color: var(--contrast-color);
  font-family: var(--heading-font);
}

/* carousel  */
/* Style for the dots to position them outside the carousel items */
.carousel-container-wrapper {
  position: relative;
}

.custom-carousel-dots {
  position: absolute;
  bottom: -30px; 
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-dot-list-style {
  margin-top: -90px !important; 

}
.LP .item {
  margin-right: 25px;
}
@media(max-width:990px){
  .custom-dot-list-style {
    margin-top: -80px;
  }
  .react-multi-carousel-dot {
    padding-top:30px ;
  }
  .LP .item {
    margin-right: 15px;
  }
}

/* Ensure the carousel item takes up 100% width and is centered */

.react-multi-carousel-list{
  padding-bottom: 3rem;
}
.react-multi-carousel-dot button{
  width: 12px;
  height: 12px;
  background-color: color-mix(in srgb, var(--default-color), transparent 85%);
  opacity: 1;
  border-color: transparent;
  border-width: 0px;
}
.react-multi-carousel-dot--active button{
  background-color: var(--accent-color) !important;
}
.react-multi-carousel-track{
  padding-left: 1rem !important;
}
.LP .PP-TC-heading{
  text-transform: uppercase;
  margin-bottom: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  margin-top: 20px;
}
.LP .textBold{
  font-weight: 600;
  line-height: 1.5;
  font-size: 17px;
  margin-bottom: 10px;
}
.LP .formInputTop{
  margin-top: 8px !important;
}





